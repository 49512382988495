import { API, Auth, graphqlOperation } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createSessionFavorite, deleteSessionFavorite } from '../graphql/mutations'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { graphql } from 'gatsby'
import { listSessionFavorites } from '../graphql/queries'
import { v4 as uuid } from 'uuid'
import Layout from '../layouts/layout'
import React from 'react'
import SEO from '../components/seo'
const { Camp } = require('../components/SimSumHelpers')
const _ = require('lodash')

class CampSession extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      favoriteUUID: props.favoriteUUID,
      isLoading: false
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.setState({
        favoriteUUID: this.props.favoriteUUID,
        isLoading: false
      })
    }
  }

  removeSessionFavorite = async favorite => {
    this.setState({ isLoading: true })
    await API.graphql(
      graphqlOperation(deleteSessionFavorite, {
        input: { id: this.state.favoriteUUID },
      })
    ).then(response => {
      console.log(response)
      this.setState({
        favoriteUUID: null,
        isLoading: false
      })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log(err)
    })
  }

  isFavorite = () => {
    return this.state.favoriteUUID != null
  }

  addSessionFavorite = async sessionId => {
    this.setState({ isLoading: true })
    await API.graphql(
      graphqlOperation(createSessionFavorite, {
        input: {
          id: uuid(),
          sessionId: this.props.session.id
        },
      })
    ).then(response => {
      console.log(response)
      this.setState({
        favoriteUUID: response.data.createSessionFavorite.id,
        isLoading: false
      })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log(err)
    })
  }

  toggleSessionFavorite = (target) => {
    target.preventDefault()
    if (!this.props.isAuthenticated) {
      window.location = '/account/'
      return
    }

    return (this.isFavorite()) ? this.removeSessionFavorite() : this.addSessionFavorite()
  }

  render () {
    const session = this.props.session

    const actionTitle = this.state.isLoading ? (<FontAwesomeIcon icon={ faSyncAlt } spin />) : (<a href="#" onClick={this.toggleSessionFavorite} className={ this.isFavorite() ? `text-danger` : `text-primary`}>{ this.isFavorite() ? 'Remove from Schedule' : 'Save to Schedule' }</a>)

    return (
      <li className={`list-group-item px-0`}>
        <div>{ session.name }</div>
        <div className="summary"><span className="font-italic">{ session.snapshot(true) }</span></div>
        { actionTitle }
      </li>
    )
  }
}

class CampTemplate extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      user: null,
      sessionFavorites: [],
      isFavorite: false,
      isLoading: true
    }
  }


  updateSessionFavorites = async () => {
    await API.graphql(
      graphqlOperation(listSessionFavorites, { limit: 1024 })
    ).then(response => {
      this.setState({ sessionFavorites: response.data.listSessionFavorites.items, isLoading: false })
    }).catch(err => {
      console.log(err)
      this.setState({ sessionFavorites: [], isLoading: false })
    })
  }

  componentDidMount = async () => {
    await Auth.currentAuthenticatedUser()
    .then(response => {
      this.setState({ user: response })
      this.updateSessionFavorites()
    }).catch(err => {
      console.log(err)
      this.setState({ user: null, isLoading: false })
    })
  }

  render () {
    const camp = new Camp(this.props.data.contentfulCamp)

    const sessions = (() => {
      if (camp.sessions.length > 0) {
        return (
          <ul className="list-group list-group-flush pb-3">
            {
              camp.sessions.map(session => {
                const favoriteIndex = _.indexOf(this.state.sessionFavorites.map(favorite => favorite.sessionId), session.id)
                const favoriteUUID = (favoriteIndex >= 0) ? this.state.sessionFavorites[favoriteIndex].id : null
                return (<CampSession session={session} favoriteUUID={favoriteUUID} isAuthenticated={ this.state.user != null } key={session.id} />)
              })
            }
          </ul>
        )
      } else {
        return camp.snapshot()
      }
    })()

    const outboundLink = (() => {
      return (camp.link) ? (<a href={ camp.link } target="_blank" rel="noopener noreferrer" className="text-muted">Visit Camp Website</a>) : (<></>)
    })()

    return (
      <Layout location={this.props.location}>
        <SEO title={ `Simple Summers - ${ camp.name }` } description={ camp.summary } keywords={ camp.tags || [] } />
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-12">

              <div className="d-flex">
                <div className="flex-grow-1">
                  <h2>{ camp.name }</h2>
                  <h3>{ camp.organization.name }</h3>
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pb-3">
              { (camp.image) ? (<img src={ camp.image } id="headerImage" alt="Simple Summers" className="img-fluid pb-5" />) : null }
              <div dangerouslySetInnerHTML={{ __html: camp.description }} />
              { outboundLink }
            </div>
            <div className="col-lg-6 pb-3">
              { sessions }
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CampTemplate

export const pageQuery = graphql`
  query($id: String!) {
    contentfulCamp(contentful_id: { eq: $id }) {
      contentful_id
      name
      category
      link
      images {
        file {
          url
        }
      }
      description {
        childContentfulRichText {
          html
        }
      }
      organization {
        contentful_id
        name
      }
      sessions {
        contentful_id
        name
        minGrade
        maxGrade
        minAge
        maxAge
        price
        startDate
        endDate
        address {
          location {
            lat
            lon
          }
          city
        }
      }
    }
  }
`
